.works {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.works h1 {
  font-size: 120px;
}

.works li {
  font-size: 18px;
}

.works div:last-child {
  margin-top: 80px;
}

.project-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  box-sizing: content-box;
  border-bottom: 1px solid grey;
  padding: 10px 0px 10px 0px;
}

.category-item.active {
  text-decoration: underline;
  text-underline-offset: 20px;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .works h1 {
    font-size: 90px;
  }

  .works li {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .works h1 {
    font-size: 70px;
  }

  .works li {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .works h1 {
    font-size: 60px;
    text-align: center;
  }

  .category-item {
    font-size: 14px;
  }

  .project-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .project-item span:first-child {
    font-weight: 500;
  }
  .project-item .project-categories span {
    font-weight: 400;
  }
}

@media (max-width: 576px) {
  .works h1 {
    font-size: 60px;
  }

  .category-item {
    font-size: 12px;
  }

  .project-item {
    padding: 5px 0;
  }
  .categories-container {
    margin-bottom: 20px !important;
  }

  .project-item .project-categories {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    justify-content: right;
  }
  .project-item .project-categories .category-item {
    display: inline-block;
    font-size: 12px;
  }
}

.project-item {
  position: relative;
  cursor: pointer;
  box-sizing: content-box;
}

.project-tooltip {
  position: absolute;
  z-index: 1000;
  width: auto;
  max-height: calc(100vh - 20px);
  overflow: auto;
  padding: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.project-tooltip.show {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.project-tooltip img {
  max-width: 100%;
  width: 300px;
  height: auto;
  animation: fadeIn 0.8s;
  transition: opacity 0.5s ease-in-out;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: black !important;
}

.paggination-btn-dark-mode {
  background-color: white !important;
  color: black !important;
}

.slide-in-left-hidden {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
