.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  height: 100vh;
}

.about h1 {
  font-size: 120px;
  text-align: center;
}

.about p {
  font-size: 16px;
  max-width: 800px;
  width: 100%;
  line-height: 20px;
}

.about img {
  cursor: pointer;
  transition: 0.2s;
}

.about img:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
.social-icons-container {
  margin-top: 40px !important;
}

@media (max-width: 1200px) {
  .about h1 {
    font-size: 120px;
  }

  .about p {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (max-width: 992px) {
  .about h1 {
    font-size: 100px;
  }

  .about p {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 768px) {
  .about h1 {
    font-size: 80px;
  }

  .about p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 576px) {
  .about {
    margin-top: 0;
    padding: 10px;
  }

  .about h1 {
    font-size: 60px;
  }

  .about p {
    font-size: 18px;
    line-height: 24px;
  }

  .about .social-icons-container {
    flex-wrap: nowrap;
  }
  .social-icons-container {
    margin-top: 35px !important;
  }
}

.slide-in-left-hidden {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 1000ms;
}
.fade-in-hidden {
  opacity: 0;
}
.fade-in {
  opacity: 1;
  transition: opacity 1000ms;
}
