ul li .nav-link {
  color: black;
  font-size: 18px;
  font-weight: 400;
  transition: 0.3s;
  background-color: transparent !important;
}

ul li .nav-link:hover {
  font-weight: 600;
  transition: 0.3s;
}

header {
  position: relative;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 1000;
  background: #fff;
}

header h6 {
  font-size: 22px;
  cursor: pointer;
}

header img {
  cursor: pointer;
  position: absolute;

  transform: translateY(-60%);
  right: 0;
}

.navbar-brand {
  background-color: transparent !important;
  font-size: 18px !important;
}
