@import "~react-image-gallery/styles/css/image-gallery.css";

.view-project {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 150px;
}

.project-info h2 {
  font-size: 120px;
}

.project-info p:nth-child(2) {
  font-size: 22px;
  color: black;
}

.project-info p:nth-child(4) {
  font-size: 22px;
  background-color: black;
  border-radius: 40px;
  color: white;
  padding: 5px 30px;
  text-transform: lowercase;
  font-size: 16px;
  width: fit-content;
}

.category {
  background-color: black;
  width: fit-content;
  color: white;
  padding: 10px;
  border-radius: 50px;
}
.software-list {
  display: flex;
  flex-direction: column;
}

.back-btn {
  position: absolute;
  margin-top: -300px;
  margin-left: -60px;
  cursor: pointer;
  transition: 0.3s;
}

.back-btn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.image-gallery img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.modal-image {
  width: 100%;
  height: 100%;
}

.modal-body {
  display: flex;
  justify-content: center;
  height: 500px;
}

.modal-body button:first-child {
  position: absolute;
  left: 0;
  top: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-body button:first-child img:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.modal-body button:first-child img {
  transition: 0.3s;
}

.modal-body button:last-child {
  position: absolute;
  right: 0;
  top: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-body button:last-child img:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.modal-body button:last-child img {
  transition: 0.3s;
}

.modal-body button img {
  width: 50px;
  height: 50px;
}

.link-to-project {
  background-color: #a3a7b7;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 5px 25px;
  border-radius: 50px;
}

.custom-carousel {
  max-width: 600px;
  margin: auto;
}

.custom-carousel img {
  height: 400px;
  object-fit: cover;
}

.image-gallery img {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.image-gallery-thumbnail-image {
  object-fit: cover !important;
  width: 100% !important;
  height: 70px !important;
  z-index: 9999;
}

.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active {
  border: none !important;
}

.image-gallery-right-nav svg,
.image-gallery-left-nav svg {
  display: none !important;
}

.image-gallery-icon {
  filter: drop-shadow(0 0 0 #1a1a1a) !important;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left {
  z-index: 1;
}

.image-gallery-center {
  width: fit-content;
}

.image-gallery-thumbnail {
  transform: scale(1) !important;
  transition: none !important;
}

.image-gallery-thumbnail {
  transition: none;
}

.image-gallery-thumbnail:hover .image-gallery-thumbnail-inner {
  transform: none;
}

@media only screen and (max-width: 600px) {
  .project-info h2 {
    font-size: 50px;
  }
}

.media {
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-bottom: 30px;
}

.video-container {
  position: relative;
  display: inline-block;
}

.video {
  width: fit-content !important;
  height: auto !important;
}

.play-icon {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100%;
  max-width: 50px;
  height: auto;
  z-index: 1;
}

@media (max-width: 768px) {
  .video-container,
  .media {
    width: 100% !important;
  }
  .play-icon {
    top: 41%;
    max-width: 30px;
  }
}

@media (max-width: 768px) {
  .hide-on-phone {
    display: none;
  }
}
