html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul {
  margin: 0 !important;
  margin-block-start: 0 !important;
  padding-inline-start: 0 !important;
}
#scrollableContainer {
  height: 100vh;
  overflow-y: auto;
}

.dark-mode {
  background-color: #000 !important;
  color: white !important;
  transition: background-color 0.3s;
  transition: color 0.3s;
}

.dark-mode .project-item::before {
  background-color: white;
}

.dark-mode .project-item::after {
  background-color: white;
}

.dark-mode form button {
  background-color: white;
  color: black;
  transition: 0.3s;
}

.dark-mode form button:hover {
  background-color: #818181;
  transition: 0.3s;
}

.dark-mode form input {
  background-color: #000;
  color: white;
  transition: 0.3s;
}

.dark-mode form textarea {
  background-color: #000;
  color: white;
  transition: 0.3s;
}

.dark-mode form input::placeholder {
  color: white;
}

.dark-mode form textarea::placeholder {
  color: white;
}

.dark-mode form input:focus {
  background-color: #000;
  transition: 0.3s;
}

.dark-mode form textarea:focus {
  background-color: #000;
  transition: 0.3s;
}

.dark-mode .form-control:focus {
  border-color: white !important;
  box-shadow: none !important;
}
ul li {
  list-style-type: none;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
}

p {
  margin: 0;
}

ul li:hover {
  font-weight: 600;
  transition: 0.3s;
}

ul li {
  list-style-type: none;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}

ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.3s;
}
