.contact h1 {
  font-size: 120px;
}

.form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  color: #7e7e7e !important;
  padding: 0 0 10px 0 !important;
  font-size: 18px !important;
  border-color: #7e7e7e !important;
}

.form-control:focus {
  border-color: black !important;
  box-shadow: none !important;
}

textarea::placeholder {
  transform: translateY(80px);
}

form {
  max-width: 900px;
  width: 100%;
}

form button {
  background-color: #000;
  border: none;
  color: white;
  font-size: 20px;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 400;
  padding: 10px 40px;
  margin-top: 20px;
  transition: 0.3s;

  border-radius: 75px;
}

form button:hover {
  background-color: #333;
  transition: 0.3s;
}

@media (max-width: 1200px) {
  .contact h1 {
    font-size: 120px;
  }

  .form-control {
    font-size: 20px !important;
  }

  form button {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .contact h1 {
    font-size: 80px;
  }

  .form-control {
    font-size: 18px !important;
  }

  form button {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .contact h1 {
    font-size: 60px;
  }

  .form-control {
    font-size: 16px !important;
  }

  form button {
    font-size: 16px;
    padding: 10px 30px;
  }

  textarea::placeholder {
    transform: translateY(50px);
  }
}

.slide-in-left-hidden {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
