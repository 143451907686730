.nav-dots {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.dot {
  display: block;
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #adadad;
  cursor: pointer;
  z-index: 0;
}

.dot.active {
  background-color: black !important;
  height: 15px;
  width: 15px;
  z-index: 9999;
}
