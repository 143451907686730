/* .snap-scroll-container {
  scroll-snap-type: y proximity;
  overflow-y: auto;
  height: 100vh;
  scroll-behavior: smooth;
} */

.snap-scroll-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
