.hero {
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;

  margin-bottom: 80px;
}

.hero h1 {
  font-size: 120px;
  line-height: 110px;
  letter-spacing: -5px;
}

.hero p {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 20px;
}

.hero div div:last-child {
  margin-bottom: 6px;
}

@media (max-width: 1200px) {
  .hero h1 {
    font-size: 120px;
    line-height: 120px;
    letter-spacing: -6px;
  }

  .hero p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -4px;
  }

  .hero p {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .hero h1 {
    font-size: 60px;
    line-height: 55px;
    letter-spacing: -0.08rem;
  }

  .hero p {
    font-size: 1rem;
  }
  .hero {
    margin-bottom: 225px;
  }
}

.typewriter-text {
  display: inline-block;
  border-right: 2px solid black;
  white-space: nowrap;
  overflow: hidden;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}

.Typewriter .Typewriter__cursor {
  display: none !important;
  color: red;
}
